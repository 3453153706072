<template>
	<card-image v-if="isVisible" :src="props.image" :size="{ratio: props.ratio, width: 400}" gradient="to bottom, rgba(0,0,0,.3), rgba(0,0,0,.7)" :blur="0.5" class="w-full h-120">
		<btn class="absolute top-0 right-0" color="white" text icon @click="hideHeader">
			<icon :icon="icons.close"/>
		</btn>
		<div class="flex flex-column h-full justify-end px-16 py-8">
			<p class="text-sub text-gray-100 font-bold leading-spaced mb-4" :style="{width: 'fit-content', borderBottom: `2px solid ${props.color ?? $config.public.colors.primary}`}">{{ props.title }}</p>
			<p v-if="props.description" class="text-xs text-gray-100 leading-spaced mb-0">{{ props.description }}</p>
		</div>
	</card-image>
</template>

<script setup lang="ts">
import {closeIcon} from "@spoferan/nuxt-spoferan/icons";
import {parseBoolean} from "@spoferan/spoferan-ts-core";

const props = withDefaults(defineProps<{
	// An identifier for hide storage
	id: string,

	title: string,
	image: string,
	description?: string,
	ratio?: number,
	color?: string,
}>(), {
	ratio: 4
});

const storageKey = `hide-header-${props.id}`;

const icons= {
	close: closeIcon
};

const isVisible = ref(true);

function hideHeader() {
	isVisible.value = false;
	localStorage.setItem(storageKey, 'true');
}

onMounted(() => {
	isVisible.value = !parseBoolean(localStorage.getItem(storageKey));
})

</script>
