import {useMainModelSearchPage} from "./useMainModelSearchPage";
import { ref, computed, watch, onMounted} from 'vue'
import type {Ref} from 'vue';
import type {LatLng, Event, Club} from "@spoferan/spoferan-ts-core";
import {debounce, deepEqual} from "@spoferan/spoferan-ts-core";
import {useIndexStore} from "../store";
import {GeolocationService} from "@spoferan/nuxt-spoferan/services";

type MapModel = Event|Club;
interface MapModelGroup {
    amount: number,
    lat: number,
    lng: number
}

export function useMapMainModelSearchPage(mapAction: string, filterData: Ref<{ [key: string]: any }>, params: { [key: string]: any } = {}) {
    const {$device, $apiFetch} = useNuxtApp();
    const indexStore = useIndexStore();

    const {
        inputFilterData,
        filterExists,
        deviceCoordinates,

        handleApplyingFilter,
        resetFilter,
        unsetFilter,
        handleSubmittedFilter,
        handleOpenedFilter,
        refreshDeviceCoordinates
    } = useMainModelSearchPage(filterData);

    const mapZoom: Ref<number> = ref(6);
    const lastZoomOnFilter: Ref<number|null> = ref(null);
    const mapBounds: Ref<{_northEast: LatLng, _southWest: LatLng}|null> = ref(null);
    const mapMarkerGroups: Ref<MapModelGroup[]> = ref([]);
    const mapModels: Ref<MapModel[]> = ref([]);
    const showMapFullScreen = ref(false);

    // The map component
    const mapElement = ref(null);

    // Whether the desktop style should be shown on which the map is presented full height next to the list
    const showInRow = computed(() => {
        return $device.isDesktop;
    });

    const nativeKeyboardHeight = 350;
    const floatingListDefaultHeight = 320;
    const floatingListMinHeight = 160;
    const floatingListHeight = ref(floatingListDefaultHeight);
    const floatingListAnchors = ref([floatingListDefaultHeight]);

    watch(filterData, () => {
        loadModelsForMap();
    }, {
        deep: true
    });

    watch(mapBounds, (newBounds, oldBounds) => {
        if (!deepEqual(newBounds, oldBounds)) {
            loadModelsForMap();
        }
    });

    function focusMap() {
        floatingListHeight.value = floatingListMinHeight;
    }

    function focusMarkerGroup(markerGroup: MapModelGroup) {

        focusMap();

        // If only this marker group exists in the bounding, and we are pretty much zoomed in, we just fetch all items individually
        if (mapMarkerGroups.value.length === 1 && mapZoom.value > 10) {
            loadModelsForMapNow(false);
            return;
        }

        let zoom = mapZoom.value + 1;

        // If less than 10 items are in the group, we zoom with twice the factor
        if (markerGroup.amount < 10) {
            zoom = zoom + 1
        }

        zoom = Math.min(zoom, 18);

        if (mapElement.value) {
            mapElement.value.flyTo([markerGroup.lat, markerGroup.lng], false, zoom)
        }
    }

    const loadModelsForMap = debounce(function (grouped = true) {
        loadModelsForMapNow(grouped);
    }, 100);

    function loadModelsForMapNow(grouped = true) {
        if (!mapBounds.value || !Object.keys(mapBounds.value).length) {
            return;
        }

        // If no more items can be retrieved, we abort
        if (lastZoomOnFilter.value && !mapMarkerGroups.value.length && lastZoomOnFilter.value < mapZoom.value) {
            return;
        }

        // We force individual items when only one group exists and the map is pretty much zoomed
        if (mapMarkerGroups.value.length <= 1 && mapZoom.value > 10) {
            grouped = false;
        }

        const northEast = mapBounds.value._northEast;
        const southWest = mapBounds.value._southWest;
        const data = {
            ...filterData.value,
            ...params,
            min_lat: parseCoordinateForRequest(Math.min(northEast.lat, southWest.lat)),
            min_lng: parseCoordinateForRequest(Math.min(northEast.lng, southWest.lng)),
            max_lat: parseCoordinateForRequest(Math.max(northEast.lat, southWest.lat)),
            max_lng: parseCoordinateForRequest(Math.max(northEast.lng, southWest.lng)),
            accuracy: grouped ? convertZoomToAccuracy(mapZoom.value) : 0
        };

        $apiFetch(mapAction, {
            params: data,
            guest: true
        }).then((data: any) => {
            mapMarkerGroups.value = data.data.groups;
            mapModels.value = buildUniquePositionedMapModels(data.data.models);
            lastZoomOnFilter.value = mapZoom.value;
        })
    }

    /**
     * Parses the specified coordinate to a reduced accuracy to improve caching.
     */
    function parseCoordinateForRequest(coordinate: number) {
        return parseFloat(coordinate.toString()).toFixed(1);
    }

    /**
     * Ensures that the given map models have unique lat lngs by shifting the lat lngs a bit
     */
    function buildUniquePositionedMapModels(mapModels: MapModel[]) {
        return mapModels && mapModels.length ? mapModels.map(mapModel => {
            const copyModel = {...mapModel};

            // Check if another map model with the same lat lngs exist
            const sameLatLngModels = mapModels.filter(indexModel => GeolocationService.isSame({lat: mapModel.lat, lng: mapModel.lng}, {lat: indexModel.lat, lng: indexModel.lng}, 10));
            if (sameLatLngModels.length > 1) {
                copyModel.lat = copyModel.lat + ((Math.random() - 0.5) * 2 * 0.001);
                copyModel.lng = copyModel.lng + ((Math.random() - 0.5) * 2 * 0.001);
            }
            return copyModel;
        }) : mapModels;
    }

    function convertZoomToAccuracy(zoom: number) {
        if (zoom >= 10.5) {
            return 0;
        } else if (zoom >= 10) {
            return 0.06;
        } else if (zoom >= 9.5) {
            return 0.09;
        } else if (zoom >= 9) {
            return 0.15;
        } else if (zoom >= 8.5) {
            return 0.2;
        }  else if (zoom >= 8) {
            return 0.3;
        } else if (zoom >= 7.5) {
            return 0.45;
        } else if (zoom >= 7) {
            return 0.6;
        } else if (zoom >= 6.5) {
            return 0.9;
        } else if (zoom >= 6) {
            return 1.2;
        } else if (zoom >= 5.5) {
            return 1.35;
        } else if (zoom >= 5) {
            return 1.5;
        } else if (zoom >= 4.5) {
            return 2.5;
        } else if (zoom >= 4) {
            return 4;
        }

        return 5;
    }

    function handleSearchInputFocus() {
        if (!$device.isDesktop) {
            indexStore.isHeaderVisible = false;

            // We need to wait until the render of the mobile's soft keyboard is initialized to ensure correct height calculations
            setTimeout(() => {
                refreshFloatingListAnchors(true);
                floatingListHeight.value = floatingListAnchors.value[floatingListAnchors.value.length - 1];
            }, 0);
        }
    }

    function handleSearchInputBlur() {
        if (!$device.isDesktop) {
            indexStore.isHeaderVisible = true;

            // We need to wait until the removal of the mobile's soft keyboard is initialized to ensure correct height calculations
            setTimeout(() => {
                refreshFloatingListAnchors(false);
                floatingListHeight.value = floatingListDefaultHeight;
            }, $device.isNative ? 250 : 0); // Native devices need a bit more time to calculate the keyboard height
        }
    }

    function refreshFloatingListAnchors(hasFocusedSearch = false) {
        const viewportHeight = window.innerHeight ?? 0;

        // The min height of the map to always display to allow focusing the map via tapping
        const minMapHeight = 32;

        const toolbarHeight = 94 + 56;
        const maxHeight = viewportHeight - toolbarHeight - minMapHeight - ($device.isNative && hasFocusedSearch ? nativeKeyboardHeight : 0);
        floatingListAnchors.value = [floatingListDefaultHeight, maxHeight];
    }

    onMounted(() => {
        loadModelsForMapNow();
        refreshFloatingListAnchors();
    });

    return {
        filterData,
        inputFilterData,
        filterExists,
        deviceCoordinates,
        mapModels,
        mapZoom,
        showInRow,
        mapBounds,
        showMapFullScreen,
        mapMarkerGroups,
        mapElement,
        floatingListHeight,
        floatingListAnchors,
        floatingListMinHeight,
        focusMap,
        handleApplyingFilter,
        handleSearchInputFocus,
        handleSearchInputBlur,
        resetFilter,
        unsetFilter,
        handleSubmittedFilter,
        handleOpenedFilter,
        focusMarkerGroup,
        refreshDeviceCoordinates
    }
}